<div>
	<mat-toolbar fxLayout="row" fxLayoutAlign="space-between center">
		@if(projectId==472){
		<span style="color: var(--feedbalia-color-background-500)">{{ "tipos_entrada.title" | translate }}</span>

		}@else {
		<span>{{ "tipos_entrada.title" | translate }}</span>

		}
		<mat-icon mat-button [mat-dialog-close]="false">close</mat-icon>
	</mat-toolbar>

	<mat-action-list>
		<button mat-list-item *ngFor="let tipoEntrada of data.tiposEntradas" [mat-dialog-close]="tipoEntrada">
			<fa-icon class="mr-8" [icon]="tipoEntrada.iconName"></fa-icon>
			{{ tipoEntrada.name }}
		</button>
	</mat-action-list>
</div>
