import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { environment } from "../../../environments/environment";
import { AuthService } from "../auth/auth.service";
import { AlertError } from "../error-handler";
import { HelpersService } from "../helper/helpers.service";
import { ProjectService } from "../project/project.service";

@Injectable({
	providedIn: "root",
})
export class PostsService {
	constructor(private http: HttpClient, private authSvc: AuthService, private projectSvc: ProjectService, private helpersSvc: HelpersService) {}

	categories(): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/posts/categories";
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	hashtags(): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/posts/hashtags";
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	postInformative(numPage: number, filtros: any): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const params = {
				page: numPage,
				pageSize: environment.pageSize,
			};

			if (filtros.categoria !== 0) {
				params["categoria"] = filtros.categoria;
			}
			if (filtros.hashtags !== 0) {
				params["hashtag"] = filtros.hashtags;
			}
			if (filtros.autor !== "") {
				params["autor"] = filtros.autor;
			}

			const url = environment.urlDomain + "/api/v1/posts/postInformative?" + this.helpersSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	postTechnical(numPage: number, filtros: any): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const params = {
				page: numPage,
				pageSize: environment.pageSize,
			};

			if (filtros.categoria !== 0) {
				params["categoria"] = filtros.categoria;
			}
			if (filtros.hashtags !== 0) {
				params["hashtag"] = filtros.hashtags;
			}
			if (filtros.autor !== "") {
				params["autor"] = filtros.autor;
			}

			const url = environment.urlDomain + "/api/v1/posts/postTechnical?" + this.helpersSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	recentNews(numPage: number, filtros?: any): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const params = {
				page: numPage,
				pageSize: environment.pageSize,
			};

			if (filtros && filtros.categoria !== 0) {
				params["categoria"] = filtros.categoria;
			}
			if (filtros && filtros.hashtags !== 0) {
				params["hashtag"] = filtros.hashtags;
			}
			if (filtros && filtros.autor !== "") {
				params["autor"] = filtros.autor;
			}
			if (filtros && filtros.funespana_especial !== "") {
				params["funespana_especial"] = filtros.funespana_especial;
			}

			const url = environment.urlDomain + "/api/v1/posts/postRecentNews?" + this.helpersSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	canCreatePost() {
		const accessToken = this.authSvc.getAccessToken();

		const options = {
			headers: new HttpHeaders({
				Authentication: "Bearer " + accessToken,
				"Content-Type": "application/json; charset=utf-8",
			}),
		};

		const url = environment.urlDomain + "/api/v1/posts/maxinformative";

		return new Observable<any>((observer) => {
			this.http.post(url, {}, options).subscribe(
				(data) => {
					console.log("canCreatePost", data);
					observer.next(data);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	postComments(postId: number): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/posts/" + postId + "/postComments";
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	postLikes(postId: number): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/posts/" + postId + "/postLikes";
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	toogleLike(postId: number): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const body = {};

			const url = environment.urlDomain + "/api/v1/posts/toggleLike/" + postId;
			this.http.post(url, body, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	createComment(postId, textComment) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const body = {
				content: textComment,
			};

			const url = environment.urlDomain + "/api/v1/posts/comment/" + postId;
			this.http.post(url, body, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	editComment(commentId, textComment) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const body = {
				content: textComment,
			};

			const url = environment.urlDomain + "/api/v1/posts/comment/edit/" + commentId;
			this.http.put(url, body, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	deleteComment(commentId) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/posts/comment/" + commentId;
			this.http.delete(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	postCommentsComment(commentId: number): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/posts/comment/" + commentId + "/comments";
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	postLikesComment(commentId: number): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/posts/comment/likes/" + commentId;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	toogleLikeComment(commentId: number): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const body = {};

			const url = environment.urlDomain + "/api/v1/posts/comment/toggleLike/" + commentId;
			this.http.post(url, body, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	respondComment(postId, commentId, textComment) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const body = {
				content: textComment,
			};

			const url = environment.urlDomain + "/api/v1/posts/comment/" + postId + "/response/" + commentId;
			this.http.post(url, body, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	createPostInformative(formValue) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/posts/";
			this.http.post(url, formValue, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	createPost(formValue) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/posts/news";
			this.http.post(url, formValue, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	createNotaTecnica(formValue) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/posts/technical";
			this.http.post(url, formValue, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	toogleFixed(postId) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const body = {};

			const url = environment.urlDomain + "/api/v1/posts/toggleFixed/" + postId;
			this.http.post(url, body, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getOneDocument(postId: number): Observable<Blob> {
		const accessToken = this.authSvc.getAccessToken();
		const headers = new HttpHeaders({
			Authentication: "Bearer " + accessToken,
		});
		const url = `${environment.urlDomain}/api/v1/posts/getOneDocument/${postId}`;
		return this.http.get(url, { headers, responseType: "blob" });
	}

	deleteOtherComment(commentId) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/posts/tandem/delete/" + commentId;
			this.http.put(url, {}, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getListManagerPublicaciones(): Observable<any> {
		return new Observable<any>((oberver) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/admin/getListManagerPublicaciones";
			this.http.get(url, options).subscribe(
				(response: any) => {
					oberver.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}
}
