import { Component, Input, OnInit } from "@angular/core";

@Component({
	selector: "kpi-data-component",
	templateUrl: "./kpi-data.component.html",
	styleUrls: ["./kpi-data.component.scss"],
})
export class KpiDataComponent implements OnInit {
	@Input() data: any;

	currentPage: number = 0;
	currentData: any;

	displayedColumns: string[] = ["key", "value"];
	dataSource = [];

	constructor() {}

	ngOnInit(): void {
		//console.log('KpiData', this.data);
		if (this.data && this.data.length) {
			this.setData();
		}
	}

	nextPage() {
		if (this.currentPage >= this.data.length - 1) {
			return;
		}
		this.currentPage++;
		this.setData();
	}

	previousPage() {
		if (this.currentPage === 0) {
			return;
		}
		this.currentPage--;
		this.setData();
	}

	setData() {
		this.currentData = this.data[this.currentPage];
		try {
			this.currentData.metadata_content = JSON.parse(this.currentData.metadata_content);
		} catch {}
		//Set dataSource
		this.dataSource = [];
		for (const key in this.currentData.metadata_content) {
			this.dataSource.push({ key, value: this.currentData.metadata_content[key] });
		}
		//console.log('DataSource', this.dataSource);
	}
}
