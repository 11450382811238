import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { environment } from "environments/environment";

@Component({
	selector: "app-media-dialog",
	templateUrl: "./media-dialog.component.html",
	styleUrls: ["./media-dialog.component.scss"],
})
export class MediaDialogComponent implements OnInit {
	file: any;
	filePath: string;

	constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
		this.filePath = environment.urlDomainEvaluationFiles;
		this.file = data.file;
	}

	ngOnInit(): void {}

	close() {
		this.data.close();
	}
}
