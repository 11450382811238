import { Component, Input, OnInit } from "@angular/core";
import moment from "moment";

@Component({
	selector: "date-range-input",
	templateUrl: "./date-range-input.component.html",
	styleUrls: ["./date-range-input.component.scss"],
})
export class DateRangeInputComponent implements OnInit {
	@Input() form: any = null;
	@Input() name: string = "";
	@Input() class: string = "";
	@Input() showClear: boolean = false;
	@Input() placeholder: string = "";

	customRanges: any;
	localeRangeDate: any;

	constructor() {
		this.localeRangeDate = {
			applyLabel: "OK",
			format: "DD-MM-YYYY",
			customRangeLabel: "Elegir rango",
			//daysOfWeek: moment.weekdaysMin(),
			//monthNames: moment.monthsShort(),
			firstDay: 1,
			daysOfWeek: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sá"],
			monthNames: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
		};

		const localeHoy = "Hoy";
		const localeAyer = "Ayer";
		const localeUlt7Days = "Últimos 7 días";
		const localeUlt30Days = "Últimos 30 días";
		const localeEsteMes = "Este mes";
		const localeMesAnterior = "Mes anterior";

		this.customRanges = {};
		this.customRanges[localeHoy] = [moment(), moment()];
		this.customRanges[localeAyer] = [moment().subtract(1, "days"), moment().subtract(1, "days")];
		this.customRanges[localeUlt7Days] = [moment().subtract(6, "days"), moment()];
		this.customRanges[localeUlt30Days] = [moment().subtract(29, "days"), moment()];
		this.customRanges[localeEsteMes] = [moment().startOf("month"), moment().endOf("month")];
		this.customRanges[localeMesAnterior] = [moment().subtract(1, "month").startOf("month"), moment().subtract(1, "month").endOf("month")];
	}

	ngOnInit(): void {}
}
