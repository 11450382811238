<div fxLayout="row" fxLayoutAlign="space-between center" class="fuse-card card-border-top" *ngIf="!jornada_concluida">
	<div fxLayout="column" fxLayoutAlign="center center" class="p-16" fxLayoutGap="10px">
		<span
			*ngIf="(estadoActual === estados.SALIDA || estadoActual === estados.AUTOCIERRE) && tiempoTrabajado === 0">{{
			"time_control.title" | translate
			}}</span>
		<span *ngIf="tiempoTrabajado > 0" class="state-indicator">
			<strong *ngIf="estadoActual === estados.TRABAJANDO">{{ "time_control.working" | translate }}</strong>
			<strong *ngIf="estadoActual === estados.EN_PAUSA">{{ "time_control.in_pause" | translate }}</strong>
			<strong *ngIf="estadoActual === estados.SALIDA || estadoActual === estados.AUTOCIERRE">{{
				"time_control.checkout" | translate }}</strong>
			<br />
			<!-- <small *ngIf="regEdit">{{ (estadoActual === estados.SALIDA || estadoActual === estados.AUTOCIERRE) ? 'a' : 'desde'}} <span class="ion-hide-sm-down">las</span> {{momentHora(regEdit.fecha)}} 
                &nbsp;<mat-icon class="editBtn" (click)="openModaEditarRegistro(template)">edit</mat-icon>
            </small> -->
		</span>
		<h1 *ngIf="(estadoActual === estados.SALIDA || estadoActual === estados.AUTOCIERRE) && tiempoTrabajado === 0"
			class="time-text current-date">
			{{ currentDate | date: "dd-MM-yyyy" }}
		</h1>
		<h1 *ngIf="estadoActual === estados.TRABAJANDO || estadoActual === estados.EN_PAUSA" class="time-text">{{
			momentTiempoTrabajado() }}</h1>
		<h1 *ngIf="(estadoActual === estados.SALIDA || estadoActual === estados.AUTOCIERRE) && tiempoTrabajado > 0"
			class="time-text">
			{{ momentTiempoTrabajado() }}
		</h1>
	</div>

	<div *ngIf="estadoActual === estados.SALIDA || estadoActual === estados.AUTOCIERRE" fxLayout="column"
		fxLayoutAlign="center center" class="p-16">
		<div style="width: 40px" class="statusIconDiv">
			<mat-icon style="font-size: 40px; cursor: pointer"
				(click)="openModalTiposEntradas()">play_circle_filled</mat-icon>
		</div>
		<span>{{ "time_control.checkin" | translate }}</span>
	</div>

	<div *ngIf="estadoActual === estados.TRABAJANDO" fxLayout="column" fxLayoutAlign="center center" class="p-16">
		<div style="width: 40px" class="statusIconDiv">
			<mat-icon style="font-size: 40px; cursor: pointer; color: red"
				(click)="changeStatus('stop')">stop_circle</mat-icon>
		</div>
		<span>{{ "time_control.stop" | translate }}</span>
	</div>

	<div *ngIf="estadoActual === estados.TRABAJANDO" fxLayout="column" fxLayoutAlign="center center" class="p-16">
		<div style="width: 40px" class="statusIconDiv">
			<mat-icon style="font-size: 40px; cursor: pointer"
				(click)="changeStatus('pause')">pause_circle_filled</mat-icon>
		</div>
		<span>{{ "time_control.pause" | translate }}</span>
	</div>

	<div *ngIf="estadoActual === estados.EN_PAUSA" fxLayout="column" fxLayoutAlign="center center" class="p-16">
		<div style="width: 40px" class="statusIconDiv">
			<mat-icon style="font-size: 40px; cursor: pointer"
				(click)="changeStatus('continue')">play_circle_outline</mat-icon>
		</div>
		<span>{{ "btn.continue" | translate }}</span>
	</div>

	<ng-template #template>
		<div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center">
			<label>{{ "time_control.edit_checkin" | translate }}</label>
			<mat-icon class="pointer" mat-button [mat-dialog-close]="false">close</mat-icon>
		</div>

		<mat-dialog-content>
			<mat-form-field subscriptSizing="dynamic">
				<input type="time" matInput [(ngModel)]="nuevoHorario" />
			</mat-form-field>
		</mat-dialog-content>

		<div mat-dialog-actions align="end">
			<button mat-button [mat-dialog-close]="false">{{ "general.button.cancel" | translate }}</button>
			<button mat-button [mat-dialog-close]="nuevoHorario">{{ "general.button.accept" | translate }}</button>
		</div>
	</ng-template>
</div>

<div fxLayout="row" fxLayoutAlign="space-between center" class="fuse-card card-border-top" *ngIf="jornada_concluida">
	<div fxLayout="column" fxLayoutAlign="center center" class="p-16" fxLayoutGap="10px">
		<span>No es necesario fichar</span>

		<h2 class="time-text current-date">Jornada concluida</h2>
	</div>

	<div fxLayout="column" fxLayoutAlign="center center" class="p-16">
		<div style="width: 40px" class="statusIconDiv">
			<mat-icon style="font-size: 40px; cursor: pointer">insert_emoticon</mat-icon>
		</div>
		<span>Descansa</span>
	</div>
</div>