<div>
	<mat-toolbar fxLayout="row" fxLayoutAlign="space-between center">
		<span>{{ "tipos_pausa.title" | translate }}</span>
		<mat-icon mat-button [mat-dialog-close]="false">close</mat-icon>
	</mat-toolbar>

	<mat-action-list>
		<button mat-list-item *ngFor="let tipoPausa of data.pausas" [mat-dialog-close]="tipoPausa">
			<fa-icon class="mr-8" [icon]="tipoPausa.iconName"></fa-icon>
			{{ tipoPausa.name }}
		</button>
	</mat-action-list>
</div>
