<div *ngIf="currentData" id="kpi-data" fxLayout="column">
	<h3 class="m-0 header">{{ currentData.sectionName }}</h3>

	<div class="data-table">
		<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
			<!-- Key Column -->
			<ng-container matColumnDef="key">
				<!-- <th mat-header-cell *matHeaderCellDef>Key</th> -->
				<td mat-cell *matCellDef="let element">{{ element.key }}</td>
			</ng-container>

			<!-- Value Column -->
			<ng-container matColumnDef="value">
				<!-- <th mat-header-cell *matHeaderCellDef>Value</th> -->
				<td mat-cell *matCellDef="let element">{{ element.value }}</td>
			</ng-container>

			<!-- <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr> -->
			<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
		</table>

		<div class="date">
			<p class="m-12">Mes: {{ currentData.month }} Año: {{ currentData.year }}</p>
		</div>
	</div>

	<div fxLayout="row" fxLayoutAlign="space-between" class="pagination">
		<div class="button" (click)="previousPage()"><mat-icon>arrow_back</mat-icon></div>
		<div>{{ currentPage + 1 }} / {{ data.length }}</div>
		<div class="button" (click)="nextPage()"><mat-icon>arrow_forward</mat-icon></div>
	</div>
</div>
