<div id="evaluation-comment">
	<div *ngIf="editMode" class="comment-container" fxLayoutAlign="left center">
		<div class="person-avatar mr-8" fxLayout="row" fxLayoutAlign="center flex-start">
			<img class="img-circle" [src]="getImagePerson(user.nickname)" [title]="user.displayName" />
		</div>
		<form class="" fxLayout="column" name="formComment" [formGroup]="formComment" fxFlex="1 1 0">
			<mat-form-field subscriptSizing="dynamic" appearance="outline" class="field-blank">
				<mat-label>{{ "addOn.comment.textarea.placeholder" | translate }}</mat-label>
				<textarea matInput formControlName="comentario" (keyup)="onCommentChange($event)"></textarea>
			</mat-form-field>
		</form>
		<!-- Texto enriquecido -->
		<!-- <quill-editor class="mb-24 editor comment-container" placeholder="Escriba un comentario aquí..."
        (onEditorCreated)="setComment($event)" (onContentChanged)="onCommentChange($event)"></quill-editor> -->
	</div>

	<div *ngIf="otherResults && otherResults.length">
		<div *ngFor="let result of otherResults" fxLayoutAlign="center center">
			<div *ngIf="result.comment" fxLayout="row" fxLayoutAlign="left center" class="comment-container">
				<div *ngIf="result.nickname" class="person-avatar mr-8" fxLayout="row"
					fxLayoutAlign="center flex-start">
					<img class="img-circle" [src]="getImagePerson(result.nickname)" [title]="result.displayName" />
				</div>
				<div class="m-0 comment" [innerHtml]="result.comment"></div>
			</div>
		</div>
	</div>
</div>