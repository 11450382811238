<div class="fg-comentar">
	@if(canEditComent!=0){
	<div fxLayout="row">
		<div class="person-avatar mini-avatar" fxLayout="row" fxLayoutAlign="center flex-start">
			<img class="img-circle" [src]="getImagePerson(user.username)" />
		</div>
		<div class="p-8 mb-12 wrap-comment" fxLayout="column" fxFlex="1 1 0">
			<form class="" fxLayout="column" name="form" fxFlex="1 1 0">
				<div fxlayout="row" fxLayoutAlign="flex-start" class="w-100-p">
					<fb-emoji-picker (emojiSel)="emojiSelected($event)"></fb-emoji-picker>

					<mat-form-field subscriptSizing="dynamic" appearance="outline" class="field-blank" class="w-100-p">
						<mat-label>{{ "addOn.comment.textarea.placeholder" | translate }}</mat-label>
						<textarea matInput name="comentario" [(ngModel)]="comentario"></textarea>
					</mat-form-field>
				</div>

				<div fxLayout="row" fxLayoutAlign="flex-end flex-end">
					<button mat-raised-button class="btn-feedbalia btn-comentar" (click)="saveComment()">{{ "addOn.comment.btn" | translate }}</button>
				</div>
			</form>
		</div>
	</div>
	}

	<div *ngFor="let comment of comments" fxLayout="row">
		<fb-item-comment
			*ngIf="badgeId"
			[id_badge]="badgeId"
			[comment]="comment"
			[user]="user"
			[canEdit]="canEditComent"
			class="w-100-p"
		></fb-item-comment>
		<fb-item-comment
			*ngIf="postId"
			[id_post]="postId"
			[comment]="comment"
			[user]="user"
			[canEdit]="canEdit"
			[canDelete]="canDelete"
			class="w-100-p"
		></fb-item-comment>
	</div>
</div>
