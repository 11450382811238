import { Directive, ElementRef, Input, OnChanges, OnDestroy, SimpleChanges } from "@angular/core";
import moment from "moment";
import { Subject } from "rxjs";

@Directive({
	selector: "[appDateFormat]",
})
export class DateFormatDirective implements OnDestroy, OnChanges {
	private readonly destroy$ = new Subject();

	@Input("fecha") fecha: string;

	constructor(private element: ElementRef) {}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.fecha && changes.fecha.currentValue != "" && moment(changes.fecha.currentValue).isValid())
			this.element.nativeElement.innerText = moment(changes.fecha.currentValue).format("DD/MM/YYYY");
	}

	ngOnDestroy(): void {
		this.destroy$.next(null);
		this.destroy$.complete();
	}
}
