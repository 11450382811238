<div fxLayout="row" fxLayoutAlign="space-between center" class="fuse-card card-border-top" *ngIf="incidencia">
	<div fxLayout="column" fxLayoutAlign="center center" class="p-16" fxLayoutGap="10px">
		<span>No es necesario fichar</span>

		<h2 *ngIf="incidencia.tipo == 0" class="time-text current-date">Incidencia médica</h2>
		<h2 *ngIf="incidencia.tipo == 1" class="time-text current-date">Otra incidencia</h2>
		<h2 *ngIf="incidencia.tipo == 2" class="time-text current-date">Vacaciones</h2>
	</div>

	<div *ngIf="incidencia.tipo == 0" fxLayout="column" fxLayoutAlign="center center" class="p-16">
		<div style="width: 40px" class="statusIconDiv">
			<mat-icon style="font-size: 40px; cursor: pointer">healing</mat-icon>
		</div>
		<span>¡Mejorate!</span>
	</div>

	<div *ngIf="incidencia.tipo == 1" fxLayout="column" fxLayoutAlign="center center" class="p-16">
		<div style="width: 40px" class="statusIconDiv">
			<mat-icon style="font-size: 40px; cursor: pointer">live_help</mat-icon>
		</div>
		<span>¡Ánimo!</span>
	</div>

	<div *ngIf="incidencia.tipo == 2" fxLayout="column" fxLayoutAlign="center center" class="p-16">
		<div style="width: 40px" class="statusIconDiv">
			<mat-icon style="font-size: 40px; cursor: pointer">flight_takeoff</mat-icon>
		</div>
		<span>¡Disfruta!</span>
	</div>
</div>
