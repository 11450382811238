import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import moment from "moment";

import { MatSnackBar } from "@angular/material/snack-bar";

import { BadgesService } from "../../../services/badges/badges.service";
import { ImagesService } from "../../../services/helper/images.service";
import { PostsService } from "../../../services/posts/posts.service";
import { ProjectService } from "../../../services/project/project.service";

import { Router } from "@angular/router";
import { Comment, Like } from "../../../shared/models/comun.interface";
import { User } from "../../../shared/models/user.interface";

@Component({
	selector: "fb-item-comment",
	templateUrl: "./item-comment.component.html",
	styleUrls: ["./item-comment.component.scss"],
})
export class ItemCommentComponent implements OnInit {
	@Input("id_badge") badgeId: number;
	@Input("id_post") postId: number;
	@Input("comment") comment: Comment;
	@Input("user") user: User;
	@Input("canDelete") canDelete: number;
	@Input("canEdit") canEdit: any;

	comentario: string;
	showFormComent: boolean;

	projectId: number;
	likes: Array<Like>;
	tooltipLikes: string;
	comments: Array<Comment>;
	confirmDelete = 0;
	canEditComent;
	constructor(
		private _formBuilder: UntypedFormBuilder,
		private snackBar: MatSnackBar,
		private projectSvc: ProjectService,
		private imagesSvc: ImagesService,
		private badgeSvc: BadgesService,
		private postSvc: PostsService,
		private router: Router
	) {
		this.comments = [];
		this.likes = [];
		this.tooltipLikes = "";
	}

	ngOnInit(): void {
		this.comment["enabled"] = 1;
		this.comentario = "";
		this.showFormComent = false;

		this.projectId = parseInt(this.projectSvc.getProjectId());
		moment.locale(this.user.language);
		this.loadLikesComments();
		this.canEditComent = this.canEdit;
	}

	momentDate(timestamp: number) {
		const hoy = moment();
		const objDate = moment(timestamp);
		const diffDias = hoy.diff(objDate, "days");

		let momentDate = objDate.fromNow();
		if (diffDias > 21) {
			momentDate = objDate.format("DD MMMM YYYY");
		}

		return momentDate;
	}

	getImagePerson(nickname: string) {
		return this.imagesSvc.getImageThumb(nickname);
	}

	loadLikesComments(): void {
		if (this.comment.totalComments > 0) {
			this.loadComments();
		}
		if (this.comment.totalLikes > 0) {
			this.loadLikes();
		}
	}

	loadComments(): void {
		if (this.badgeId) {
			this.badgeSvc.badgeCommentsComment(this.comment.id).subscribe((data: any) => {
				// console.log("coments comment", data);
				this.comments = data.elements;
			});
		} else if (this.postId) {
			this.postSvc.postCommentsComment(this.comment.id).subscribe((data: any) => {
				// console.log("coments comment", data);
				this.comments = data.elements;
			});
		}
	}

	loadLikes(): void {
		if (this.badgeId) {
			this.badgeSvc.badgeLikesComment(this.comment.id).subscribe((data: any) => {
				// console.log("likes badges", data);
				this.likes = data.elements;
				this.tooltipLikes = "";
				for (const like of this.likes) {
					if (this.tooltipLikes !== "") {
						this.tooltipLikes += "\n";
					}
					this.tooltipLikes += like.autor.name;
				}
			});
		} else if (this.postId) {
			this.postSvc.postLikesComment(this.comment.id).subscribe((data: any) => {
				// console.log("likes badges", data);
				this.likes = data.elements;
				this.tooltipLikes = "";
				for (const like of this.likes) {
					if (this.tooltipLikes !== "") {
						this.tooltipLikes += "\n";
					}
					this.tooltipLikes += like.autor.name;
				}
			});
		}
	}

	toogleLikeComment(comment: any): void {
		if (this.badgeId) {
			this.badgeSvc.toogleLikeComment(comment.id).subscribe(
				(data) => {
					if (comment.isLiked) {
						comment.totalLikes--;
						comment.isLiked = false;
					} else {
						comment.totalLikes++;
						comment.isLiked = true;
					}
					this.loadLikesComments();
				},
				(err) => {
					this.snackBar.open(err, "X", {
						duration: 3000,
					});
				}
			);
		} else if (this.postId) {
			this.postSvc.toogleLikeComment(comment.id).subscribe(
				(data) => {
					if (comment.isLiked) {
						comment.totalLikes--;
						comment.isLiked = false;
					} else {
						comment.totalLikes++;
						comment.isLiked = true;
					}
					this.loadLikesComments();
				},
				(err) => {
					this.snackBar.open(err, "X", {
						duration: 3000,
					});
				}
			);
		}
	}

	toogleFormCommentComment(): void {
		if (this.showFormComent) {
			this.showFormComent = false;
		} else {
			this.showFormComent = true;
		}
	}

	emojiSelected(event) {
		const emoji = event.emoji && event.emoji.native ? event.emoji.native : null;
		if (emoji) {
			this.comentario = this.comentario + event.emoji.native;
		}
	}

	saveComment() {
		if (this.comentario === "") {
			this.snackBar.open("El comentario no puedes estar vacio", "X", {
				duration: 3000,
			});
		} else {
			if (this.badgeId) {
				this.badgeSvc.respondComment(this.badgeId, this.comment.id, this.comentario).subscribe(
					(data) => {
						this.comentario = "";
						this.loadComments();
					},
					(err) => {
						this.snackBar.open(err, "X", {
							duration: 3000,
						});
					}
				);
			} else if (this.postId) {
				this.postSvc.respondComment(this.postId, this.comment.id, this.comentario).subscribe(
					(data) => {
						this.comentario = "";
						this.loadComments();
					},
					(err) => {
						this.snackBar.open(err, "X", {
							duration: 3000,
						});
					}
				);
			}
		}
	}
	deleteThis(comment) {
		this.postSvc.deleteOtherComment(comment.id).subscribe((data) => {
			//this.router.navigate(["/publicaciones"]);
			comment.enabled = 0;
			comment.id = -20;
		});
	}
}
