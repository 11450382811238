@if(this.comment["enabled"]==1){
<div fxLayout="row">
	<div class="person-avatar mini-avatar pr-16 pt-8" fxLayout="row" fxLayoutAlign="center flex-start">
		<img class="img-circle" [src]="getImagePerson(comment.autor.username)" [title]="comment.autor.username" />
	</div>
	<div fxLayout="column" class="w-100-p">
		<div class="content-comment p-16">
			<p class="m-0">{{ comment.content }}</p>
		</div>
		<div fxLayout="row" fxLayoutAlign="space-between center" class="mb-12">
			<small class="mt-4 mb-12 comment-date">
				<span *ngIf="comment.autor">
					<b *ngIf="comment.autor.name">
						{{ comment.autor.name + ", " }}
					</b></span
				>
				<span [matTooltip]="tooltipLikes" [matTooltipClass]="'tooltip-lines'" showDelay="500">{{ comment.totalLikes }} likes</span> ·
				{{ momentDate(comment.fecha) }}
			</small>
			@if(canEditComent!=0){
			<div fxLayout="row" fxLayoutAlign="center center" class="comment-actions">
				@if(canDelete && confirmDelete==0){
				<button mat-stroked-button color="primary" class="btn-delete ml-8" (click)="confirmDelete = 1">Eliminar</button>
				} @if(confirmDelete){
				<button mat-stroked-button color="primary" class="btn-delete ml-8" (click)="deleteThis(comment)">Confirmar Eliminación</button>
				}
				<div *ngIf="comment.autor.id !== user.id">
					@if(projectId!=472){
					<button *ngIf="!comment.isLiked" mat-stroked-button color="primary" class="btn-like" (click)="toogleLikeComment(comment)">Like</button>
					<button *ngIf="comment.isLiked" mat-raised-button color="primary" class="btn-like active" (click)="toogleLikeComment(comment)">Like</button>
					}@else {
					<button mat-stroked-button color="primary" class="btn-like botonFunespana" (click)="toogleLikeComment(comment)">
						{{ comment.isLiked ? "Deshacer Like" : "Like" }}
					</button>

					}
				</div>
				@if(projectId!=472){
				<button mat-stroked-button color="primary" class="btn-responder ml-8" (click)="toogleFormCommentComment()">
					{{ "addOn.comment.btn.response" | translate }}
				</button>
				}@else {
				<button mat-stroked-button color="primary" class="btn-responder ml-8 botonFunespana" (click)="toogleFormCommentComment()">
					{{ "addOn.comment.btn.response" | translate }}
				</button>
				}
			</div>
			}
		</div>
		@if(canEditComent!=0){
		<div *ngIf="showFormComent" fxLayout="row">
			<div class="person-avatar mini-avatar" fxLayout="row" fxLayoutAlign="center flex-start">
				<img class="img-circle" [src]="getImagePerson(user.username)" />
			</div>
			<div class="p-8 mb-12 wrap-comment" fxLayout="column" fxFlex="1 1 0">
				<form class="" fxLayout="column" name="form" fxFlex="1 1 0">
					<div fxLayout="row" fxLayoutAlign="flex-start" class="w-100-p">
						<fb-emoji-picker (emojiSel)="emojiSelected($event)"></fb-emoji-picker>

						<mat-form-field subscriptSizing="dynamic" appearance="outline" class="field-blank w-100-p">
							<mat-label>{{ "addOn.comment.submit" | translate }}</mat-label>
							<textarea matInput name="comentario" [(ngModel)]="comentario"></textarea>
						</mat-form-field>
					</div>
					<div fxLayout="row" fxLayoutAlign="flex-end flex-end">
						<button mat-raised-button class="btn-feedbalia btn-comentar" (click)="saveComment()">{{ "addOn.comment.btn" | translate }}</button>
					</div>
				</form>
			</div>
		</div>
		}

		<div *ngFor="let respondComment of comments" fxLayout="row">
			@if(respondComment.id >0){
			<div class="person-avatar mini-avatar pr-16 pt-8" fxLayout="row" fxLayoutAlign="center flex-start">
				<img class="img-circle" [src]="getImagePerson(respondComment.autor.username)" [title]="respondComment.autor.username" />
			</div>
			<div fxLayout="column" class="w-100-p">
				<div class="content-comment p-16">
					<p class="m-0">{{ respondComment.content }}</p>
				</div>
				<div fxLayout="row" fxLayoutAlign="space-between center" class="mb-12">
					<small class="mt-4 mb-12 comment-date">
						<span *ngIf="respondComment.autor">
							<b *ngIf="respondComment.autor.name">
								{{ respondComment.autor.name + ", " }}
							</b></span
						>
						<span [matTooltip]="tooltipLikes" [matTooltipClass]="'tooltip-lines'" showDelay="500">{{ respondComment.totalLikes }} likes</span> ·
						{{ momentDate(respondComment.fecha) }}
					</small>
					@if(canEditComent!=0){
					<div class="comment-actions">
						@if(canDelete && !respondComment.confirmDelete){
						<button mat-stroked-button color="primary" class="btn-delete ml-8" (click)="respondComment.confirmDelete = 1">Eliminar</button>
						} @if(respondComment.confirmDelete){
						<button mat-stroked-button color="primary" class="btn-delete ml-8" (click)="deleteThis(respondComment)">Confirmar Eliminación</button>
						} @if(respondComment.autor.id != user.id){ @if(projectId!=472){
						<button *ngIf="!respondComment.isLiked" mat-stroked-button color="primary" class="btn-like" (click)="toogleLikeComment(respondComment)">
							Like
						</button>
						<button
							*ngIf="respondComment.isLiked"
							mat-raised-button
							color="primary"
							class="btn-like active"
							(click)="toogleLikeComment(respondComment)"
						>
							Like
						</button>
						}@else{
						<button
							*ngIf="!respondComment.isLiked"
							mat-stroked-button
							color="primary"
							class="btn-like botonFunespana"
							(click)="toogleLikeComment(respondComment)"
						>
							{{ respondComment.isLiked ? "Deshacer Like" : "Like" }}
						</button>

						} }
					</div>
					}
				</div>
			</div>
			}
		</div>
	</div>
</div>

}
