import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { EvaluationService } from "app/services/evaluation/evaluation.service";
import { ImagesService } from "app/services/helper/images.service";

@Component({
	selector: "evaluation-comment",
	templateUrl: "./evaluation-comment.component.html",
	styleUrls: ["./evaluation-comment.component.scss"],
})
export class EvaluationCommentComponent implements OnInit {
	@Input() elementId;
	@Input() type;
	@Input() evalId;
	@Input() user;
	@Input() userResult;
	@Input() editMode;

	@Output() onChangeValue = new EventEmitter();

	formComment: UntypedFormGroup;
	otherResults;
	comment: string;

	constructor(
		private _formBuilder: UntypedFormBuilder,
		private imageSvc: ImagesService,
		private evaluationSvc: EvaluationService,
	) {}

	ngOnInit(): void {
		this.formComment = this._formBuilder.group({
			comentario: [""],
		});
		if (this.evalId) {
			this.evaluationSvc.getElementResultByEvaluation(this.elementId, this.type, this.evalId).subscribe((data) => {
				if (this.editMode) {
					// Si hay un comentario del usuario, setearlo en el formulario
					if (this.userResult && this.userResult.comment) {
						this.setComment();
					}
					this.otherResults = data.filter((r) => r.evaluatorId !== this.user.id);
				} else {
					this.otherResults = data;
				}
			});
		} else {
			// Si hay un comentario del usuario, setearlo en el formulario
			if (this.userResult && this.userResult.comment) {
				this.setComment();
			}
		}
	}

	getImagePerson(nickname: string) {
		return this.imageSvc.getImageThumb(nickname);
	}

	setComment() {
		this.formComment.setValue({
			comentario: this.userResult.comment,
		});
	}

	onCommentChange(evt) {
		//FIXME Al ser object y estar referenciado, podemos omitir el emitter y setearlo desde aquí
		this.onChangeValue.emit({
			id: this.elementId,
			target: this.type + "Id",
			comment: evt.target.value,
		});
	}
}
