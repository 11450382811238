import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ProjectService } from "app/services/project/project.service";

@Component({
	selector: "app-tipos-entrada",
	templateUrl: "./tipos-entrada.component.html",
	styleUrls: ["./tipos-entrada.component.scss"],
})
export class TiposEntradaComponent implements OnInit {
	constructor(@Inject(MAT_DIALOG_DATA) public data, public projectSvc: ProjectService) {}
	projectId = 0;
	ngOnInit(): void {
		this.projectId = parseInt(this.projectSvc.getProjectId());
	}
}
