import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import moment from "moment";
import { ControlHorarioService } from "../../services/control-horario/control-horario.service";

import { Router } from "@angular/router";
import { UserService } from "app/services/user/user.service";
import { ProfileUser } from "app/shared/models/user.interface";
import { TiposEntradaComponent } from "../tipos-entrada/tipos-entrada.component";
import { TiposPausaComponent } from "../tipos-pausa/tipos-pausa.component";
@Component({
	selector: "app-time-control-ampliado",
	templateUrl: "./time-control-ampliado.component.html",
	styleUrls: ["./time-control-ampliado.component.scss"],
})
export class TimeControlAmpliadoComponent implements OnInit {
	@Output() reload = new EventEmitter();

	tiempoTrabajado: number = 0;
	currentDate: number;
	tiposEntradas: Array<any>;
	estados: any;
	tipoEntradaSelected: any;
	estadoActual: number;
	firstEntry: number;
	lastPause: number;
	regEdit: any;
	newHour: any;
	person: any;
	nuevoHorario: any = null;
	jornada_concluida: boolean = false;
	autoCheckActivo = [];
	motivo: string = "";
	showMotivationForm = false;
	errorValidar: string = null;

	private ultimaEntrada: number;
	private tiempoTrabajoInicio: number;
	private timepoTranscurrido: number;
	private intervalCountTime: any;

	constructor(
		private controlSvc: ControlHorarioService,
		public dialog: MatDialog,
		private snackBar: MatSnackBar,
		private userSvc: UserService,
		private router: Router,
	) {
		this.initData();
	}

	ngOnInit(): void {
		this.loadTiposEntradas();
		this.getStatusUser();
		this.userSvc.profile().subscribe((data: ProfileUser) => {
			this.person = data.persona;
			this.controlSvc.checkPicadoAutomatico(this.person.id).subscribe((data) => {
				this.autoCheckActivo = data;
				console.log("CheckAuto", data);
			});
			this.controlSvc.checkJornadaCompletada(this.person.id).subscribe((data) => {
				this.jornada_concluida = data;
				console.log("JORNADA CUMPLIDA : ", data);
			});
		});

		this.controlSvc.onReloadTimeControl.subscribe(() => {
			this.stopCountTime();
			this.initData();
			this.loadTiposEntradas();
			this.getStatusUser();
		});

		this.controlSvc.onDestroyTimeControl.subscribe(() => {
			this.stopCountTime();
		});
	}

	ngOnDestroy(): void {
		console.log("destroy time-control");
		this.stopCountTime();
	}

	initData() {
		this.currentDate = Date.now();
		this.tiempoTrabajado = 0;
		this.tiempoTrabajoInicio = 0;
		this.timepoTranscurrido = 0;

		this.estados = {
			AUTOCIERRE: 3,
			SALIDA: 2,
			TRABAJANDO: 0,
			EN_PAUSA: 1,
			COMIDA: 4,
		};

		this.tiposEntradas = [];
		this.estadoActual = 2;
	}

	loadTiposEntradas() {
		this.controlSvc.getTiposEntradas().subscribe(
			(data) => {
				this.tiposEntradas = data;
			},
			(err) => {
				console.log(err);
			},
		);
	}

	getStatusUser() {
		this.controlSvc.getUserState().subscribe(
			(data) => {
				console.log("estado usuario", data);
				(this.tipoEntradaSelected = data.entryType), (this.estadoActual = data.breakType);
				this.tiempoTrabajoInicio = data.workTimeTotal;
				this.ultimaEntrada = data.lastEntry;
				this.firstEntry = data.firstEntry;
				this.lastPause = data.lastPause;
				this.regEdit = data.regEdit;

				this.getTiempoTrabajado();
				if (this.estados && this.estadoActual === this.estados.TRABAJANDO && this.ultimaEntrada !== null) {
					this.startCountTime();
				}
			},
			(err) => {
				console.log(err);
			},
		);
	}

	momentTiempoTrabajado() {
		let formatTiempoTrabajado = "00:00:00";
		if (this.tiempoTrabajado !== 0) {
			formatTiempoTrabajado = moment.utc(this.tiempoTrabajado).format("HH:mm:ss");
		}
		return formatTiempoTrabajado;
	}

	momentHora(timer) {
		return moment(timer).format("HH:mm");
	}

	getTiempoTrabajado() {
		this.tiempoTrabajado = this.tiempoTrabajoInicio + this.timepoTranscurrido;
	}

	private startCountTime() {
		this.timepoTranscurrido = 0;
		this.intervalCountTime = setInterval(() => {
			this.calculateWorkTime();
		}, 1000);
	}

	private stopCountTime() {
		this.timepoTranscurrido = 0;
		clearInterval(this.intervalCountTime);
	}

	private calculateWorkTime() {
		const now = moment();
		const momentLastDate = moment(this.ultimaEntrada);
		this.timepoTranscurrido = now.diff(momentLastDate);
		this.getTiempoTrabajado();
	}

	openModalTiposEntradas() {
		if (this.tiposEntradas.length > 0) {
			const dialogRef = this.dialog.open(TiposEntradaComponent, {
				panelClass: [],
				width: "50vw",
				disableClose: false,
				autoFocus: false,
				data: { tiposEntradas: this.tiposEntradas },
			});

			dialogRef.beforeClosed().subscribe((response) => {
				if (response) {
					this.tipoEntradaSelected = response;
					console.log("tipoEntrada Selected", this.tipoEntradaSelected);
					this.changeStatus("play");
				}
			});
		}
	}

	openModalTiposParada() {
		const dialogRef = this.dialog.open(TiposPausaComponent, {
			panelClass: [],
			width: "50vw",
			disableClose: false,
			autoFocus: false,
			data: {
				pausas: [
					{ iconLib: "fa", iconName: "pause", id: "pause", name: "Pausa" },
					{ iconLib: "fa", iconName: "utensils", id: "eat", name: "Pausa comida" },
					{ iconLib: "fa", iconName: "stop", id: "stop", name: "Parada" },
				],
			},
		});
		dialogRef.beforeClosed().subscribe((response) => {
			if (response) {
				this.changeStatus(response.id);
			}
		});
	}

	changeStatus(status) {
		// TODO: Si esta activado la geoposicion obtener la posicion del usuario
		const positionUser = {
			latitude: 0,
			longitude: 0,
			accuracy: 0,
			errorGps: "",
		};

		switch (status) {
			case "play":
				this.startTelework(positionUser);
				break;
			case "pause":
				this.stopTelework(this.estados.EN_PAUSA, positionUser);
				break;

			case "eat":
				this.stopTelework(this.estados.COMIDA, positionUser);
				break;

			case "continue":
				this.startTelework(positionUser);
				break;
			case "stop":
				this.stopTelework(this.estados.SALIDA, positionUser);
				this.reload.emit();
				break;

			default:
				break;
		}
	}

	openModaEditarRegistro(templateRef) {
		const dialogRef2 = this.dialog.open(templateRef, {
			width: "300px",
		});

		dialogRef2.beforeClosed().subscribe((response) => {
			if (response) {
				this.editarRegistro(this.regEdit, response);
			}

			this.nuevoHorario = null;
		});
	}

	startTelework(positionUser) {
		//this.loadingService.presentLoading("Guardando registro...");
		this.controlSvc.startTelework(this.tipoEntradaSelected.id, positionUser).subscribe(
			(data) => {
				//this.loadingService.hideLoading();
				this.getStatusUser();
			},
			(err) => {
				//this.loadingService.hideLoading();
				console.log(err);
			},
		);
	}

	stopTelework(estado, positionUser) {
		//this.loadingService.presentLoading("Guardando registro...");
		this.controlSvc.stopTelework(estado, positionUser).subscribe(
			(data) => {
				//this.loadingService.hideLoading();
				this.stopCountTime();
				this.getStatusUser();
			},
			(err) => {
				//this.loadingService.hideLoading();
				console.log(err);
			},
		);
	}

	editarRegistro(registro, newHour) {
		const dataUpdate = {
			id: registro.id,
			entryTime: registro.exit === 0 ? newHour : null,
			exitTime: registro.exit === 1 ? newHour : null,
		};
		console.log("dataUpdate", dataUpdate);

		//this.loadingService.presentLoading("Editando el registro...");
		this.controlSvc.updateTelework(dataUpdate).subscribe(
			(data) => {
				//this.loadingService.hideLoading();
				this.stopCountTime();
				this.getStatusUser();
			},
			(err) => {
				//this.loadingService.hideLoading();
				this.snackBar.open(err, "X", {
					duration: 3000,
				});
			},
		);
	}

	showForm() {
		this.showMotivationForm = true;
	}

	validarCheckAutomatico() {
		if (this.motivo.length) {
			this.controlSvc.updateValidation(Number(this.autoCheckActivo[0].id), 1, this.motivo).subscribe((data) => console.log("ok"));
			this.router.navigate(["/control-horario"]);
		} else {
			this.errorValidar = "*Se ha de incluir motivo";
		}
	}

	cancelarCheckAutomatico() {
		this.controlSvc.updateValidation(Number(this.autoCheckActivo[0].id), 2, "").subscribe((data) => console.log("ok"));
		this.router.navigate(["/control-horario"]);
	}
}
