import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ImagesService } from "app/services/helper/images.service";
import { PersonService } from "app/services/person/person.service";
import { EvaluationService } from "app/services/evaluation/evaluation.service";

@Component({
	selector: "app-transfer-dialog",
	templateUrl: "./transfer-dialog.component.html",
	styleUrls: ["./transfer-dialog.component.scss"],
})
export class TransferDialogComponent implements OnInit {
	filterPersonValue: string = "";
	persons: Array<any> = [];
	hasMorePersons: boolean;
	currentPagePersons: number;
	personSelected = null;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		private personSvc: PersonService,
		private imagesSvc: ImagesService,
		private evaluationSvc: EvaluationService,
	) {
		// if (data.transferedTo) {
		//     this.personSelected = data.transferedTo.personId;
		//     this.filterPersonValue = data.transferedTo.newEvaluatorName;
		// }
	}

	ngOnInit(): void {}

	filterPersons(event: Event) {
		if (this.filterPersonValue.length > 2) {
			this.currentPagePersons = 0;
			this.persons = [];
			this.getPersons();
		}
	}

	getPersons() {
		this.personSvc.getPersons(this.filterPersonValue, this.currentPagePersons, 5).subscribe(
			(personsData) => {
				this.persons = personsData.elements;
			},
			(err) => {
				console.log(err);
			},
		);
	}

	clearPerson() {
		this.personSelected = null;
		this.filterPersonValue = "";
		this.persons = [];
	}

	selectPerson(user) {
		this.personSelected = user.id;
		this.filterPersonValue = user.displayName;
		this.persons = [];
	}

	getImagePerson(nickname: string) {
		return this.imagesSvc.getImageThumb(nickname);
	}

	transfer() {
		this.evaluationSvc.transferActionPlan(this.data.personId, this.data.actionPlanId, this.personSelected).subscribe((data) => {
			if (data.success) {
				const _callback = {
					personId: this.personSelected,
					newEvaluatorName: this.filterPersonValue,
					transferId: data.id,
				};
				this.data.close(_callback);
			}
		});
	}

	removeTransfer() {}
}
