import { Component, OnInit } from "@angular/core";

import { UserService } from "app/services/user/user.service";
import { VacacionesService } from "app/services/vacaciones/vacaciones.service";

@Component({
	selector: "app-time-control-incidencia",
	templateUrl: "./time-control-incidencia.component.html",
	styleUrls: ["./time-control-incidencia.component.scss"],
})
export class TimeControlIncidenciaComponent implements OnInit {
	incidencia = null;

	constructor(
		private vacacionesSvc: VacacionesService,
		private userSvc: UserService,
	) {}

	ngOnInit(): void {
		this.userSvc.getUser().subscribe((data) => {
			this.vacacionesSvc.getUserCurrentIncidence(data.id).subscribe((data) => {
				this.incidencia = data[0];
				console.log(data);
			});
		});
	}
}
