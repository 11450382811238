<div mat-dialog-container class="container">
	<div class="close" (click)="close()">
		<mat-icon>close</mat-icon>
	</div>

	<div *ngIf="file.contentType.includes('image')" class="content">
		<img [src]="filePath + file.filePath" />
	</div>

	<div *ngIf="file.contentType.includes('video')" class="content">
		<video controls>
			<source [src]="filePath + file.filePath" type="video/mp4" />
		</video>
	</div>

	<div *ngIf="!file.contentType.includes('image') && !file.contentType.includes('video')" class="content">
		<h1>{{ file.fileName }}</h1>
		<a [href]="filePath + file.filePath" target="_blank" download>Ver fichero</a>
	</div>
</div>
