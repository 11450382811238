<ng-container *ngIf="form" [formGroup]="form">
	<input
		matInput
		ngxDaterangepickerMd
		[formControlName]="name"
		[readOnly]="true"
		autocomplete="off"
		[class]="class"
		[locale]="localeRangeDate"
		[showClearButton]="showClear"
		[placeholderLabel]="placeholder"
		[autoApply]="true"
		[ranges]="customRanges"
		[alwaysShowCalendars]="true"
		[keepCalendarOpeningWithRange]="true"
		opens="right"
	/>
</ng-container>
