import { Component, OnInit, Input, Inject } from "@angular/core";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
	selector: "app-tipos-pausa",
	templateUrl: "./tipos-pausa.component.html",
	styleUrls: ["./tipos-pausa.component.scss"],
})
export class TiposPausaComponent implements OnInit {
	constructor(@Inject(MAT_DIALOG_DATA) public data) {}

	ngOnInit(): void {}
}
